import { TAppAction, TAppState, ActionType, headerRoutes, settingsRoutes } from "../../interfaces/store/appStore";
import { makeStore } from "../makeStore";


const initialState: TAppState = {
    modal: undefined,
    toast: undefined,
    requestLoading: {},
    spinner: false,
    extensionInstalled: false,
    extensionId: process.env.REACT_APP_EXTENSION_ID,
    extensionVersion: undefined,
    onboardingStep: -2,
    headerState: undefined,
    settingsState: settingsRoutes,
    sidebar: [],
    darkMode: false,
    //connectionsFilter: undefined //I would use here the last filter remembering the page, pageSize and the object of all the filter parameters like last used, since connectionFilter os not a variable related to the app
}

const reducer = (state: TAppState, action: TAppAction): TAppState => {
    switch (action.type) {
        case ActionType.SET_MODAL: return { ...state, modal: action.payload }
        case ActionType.SET_TOAST: return { ...state, toast: action.payload }
        case ActionType.SET_REQUEST_LOADING: return { ...state, requestLoading: { ...state.requestLoading, ...action.payload } }
        case ActionType.SET_SPINNER: return { ...state, spinner: action.payload }
        case ActionType.SET_EXTENSION_INSTALLED: return { ...state, extensionInstalled: action.payload }
        case ActionType.SET_EXTENSION_ID: return { ...state, extensionId: action.payload }
        case ActionType.SET_EXTENSION_VERSION: return { ...state, extensionVersion: action.payload }
        case ActionType.SET_ONBOARDING_STEP: return { ...state, onboardingStep: action.payload }
        case ActionType.SET_HEADER_STATE: return { ...state, headerState: action.payload }
        case ActionType.SET_SETTINGS_STATE: return { ...state, settingsState: action.payload }
        case ActionType.SET_DARK_MODE: return { ...state, darkMode: action.payload }
        case ActionType.RESET_STORE: return { ...initialState }
        case ActionType.SET_SIDEBAR: return { ...state, sidebar: action.payload }
        default: return state
    }
}

const { StoreProvider: AppStoreProvider, useStoreContext: useAppStore } = makeStore(reducer, initialState)

export { AppStoreProvider, useAppStore }