import { FunctionComponent, useEffect, useState } from "react";
import AdvancedSearchFragment from "./AdvancedSearchFragment";
import { useConnectionsHandlers } from "../services/actions/connections/useConnectionsHandlers";
import debounce from "lodash.debounce";
import { useConnectionsStore } from "../stores/connections/useConnectionsStore";
import { useLocation } from "react-router-dom";
import { getID } from "../utils";

interface ISearchFilterProps {
    searchValue?: string
}

const ConnectionSearchFilter: FunctionComponent<ISearchFilterProps> = ({searchValue}) => {
    const location = useLocation()
    const {
        searchByLocation: {
            locationSearchValue = ''
        } = {},
        searchByJobTitle: {
            jobTitleSearchValue = ''
        } = {}
    } = location.state || {}

    const { setConnectionsFilterHandler } = useConnectionsHandlers()

    const { store: { connectionsFilter } } = useConnectionsStore()

    const [inputValue, setInputValue] = useState(locationSearchValue?.length ? locationSearchValue : jobTitleSearchValue?.length ? jobTitleSearchValue : searchValue ? searchValue : '');

    type SearchParamKeys = 'uid' | 'searchValue' | 'isValueSearchOr' | 'searchByName' | 'searchByHeadline' | 'searchByJobTitle' | 'searchByCompany' | 'searchByLocation' | 'searchByNote';
    const [searchParams, setSearchParams] = useState<Record<SearchParamKeys, string>>({
        uid: getID(), // unique identifier
        searchValue: '',
        isValueSearchOr: 'yes',
        searchByName: 'yes',
        searchByHeadline: 'yes',
        searchByJobTitle: 'yes',
        searchByCompany: 'yes',
        searchByLocation: 'yes',
        searchByNote: 'yes',
    });

    const updateSearchParams = debounce((updates: any) => {

        console.log('CONN FILTER PRIJE: ');
        console.log(connectionsFilter);
        console.log(updates);

        let newParams;
        // first search object
        if(!connectionsFilter.searchValues[0]?.searchValue){
            newParams = { ...connectionsFilter, searchValues: [updates] };
        } else {
            newParams = { ...connectionsFilter, searchValues: [...connectionsFilter.searchValues, updates] };
        }
        
        setConnectionsFilterHandler(newParams);

        console.log('CONN FILTER POSLIJE: ');
        console.log(newParams);


    }, 500)

    const inputChangedHandler = async (value: any) => {
        setInputValue(value);
    }

    const keyDownChangeHandler = async (event: KeyboardEvent) => {
           if (event.key === 'Enter' && inputValue) {
               event.preventDefault();
               const params = searchParams.isValueSearchOr === 'yes' ? {...searchParams, searchByNote: 'no'} : searchParams;
               updateSearchParams({...params, searchValue: inputValue});
               resetSearchParams();
           }
    }

    const resetSearchParams = () => {
        setSearchParams({
            uid: getID(),
            searchValue: '',
            isValueSearchOr: 'yes',
            searchByName: 'yes',
            searchByHeadline: 'yes',
            searchByJobTitle: 'yes',
            searchByCompany: 'yes',
            searchByLocation: 'yes',
            searchByNote: 'yes',
           });
           setInputValue('');
    }

    const updateSearchParamsQuery = (value: SearchParamKeys, type?: string) => {
        if(type === 'isValueSearchOr'){
            setSearchParams((prevState) => ({
                ...prevState,
                [type]: value.toString() === 'Or' ? 'yes' : 'no',
            }));
        } else {
            setSearchParams((prevState) => ({
                ...prevState,
                [value]: prevState[value] === 'yes' ? 'no' : 'yes',
            }));
        }
    };

    const listItems = [
        {label: 'Name', value: 'searchByName' },
        {label: 'Headline', value: 'searchByHeadline' },
        {label: 'Job title', value: 'searchByJobTitle' },
        {label: 'Company', value: 'searchByCompany' },
        {label: 'Location', value: 'searchByLocation' }
    ]

    useEffect(() => {
        if(searchValue === ''){
            setInputValue('')
        }
    }, [searchValue]);

    const listItemsWitNote = [
        ...listItems,
        {label: 'Note', value: 'searchByNote' }
    ]

    return (
        <AdvancedSearchFragment
            tabs={[{title: 'Or', slug: 'Or', selected: searchParams?.isValueSearchOr === 'yes'}, {title: 'And', slug: 'And', selected: searchParams?.isValueSearchOr !== 'yes'}]}
            infoTitle={""}
            itemsLabel={'Search by'}
            inputValue={inputValue}
            isCheckboxChecked={(value: SearchParamKeys) => searchParams[value] === 'yes'}
            setSelectedTab={(e) => updateSearchParamsQuery(e, 'isValueSearchOr')}
            searchByOptionHandler={(e) => updateSearchParamsQuery(e)}
            searchByValueHandler={(e) => { inputChangedHandler(e)}}
            keyDownHandler={(e) => { keyDownChangeHandler(e)}}
            resetSelectedValues={resetSearchParams}
            children={searchParams?.isValueSearchOr === 'yes' ? listItems : listItemsWitNote}
        />
    )

}

export default ConnectionSearchFilter