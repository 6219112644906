import { TConnectionAction, TConnectionsState, ActionType } from "../../interfaces/store/connectionStore";
import { makeStore } from "../makeStore";

const filters = {
    searchValues: [{
        isValueSearchOr: 'yes',
        searchValue: '',
        searchByName: 'yes',
        searchByCompany: 'yes',
        searchByHeadline: 'yes',
        searchByLocation: 'yes',
        searchByJobTitle: 'yes',
        searchByNote: 'no',
    }],
    searchByFollowersValue: undefined,
    searchByFollowersMaxValue: undefined,
    hiddenConnectionsValue: { value: 'no', label: 'No' },
    contactInfo: { value: 'all', label: 'All' },
    industry: { value: '', label: 'All Industries' },
    note: { value: 'all', label: 'All' },
    tagCondition: 'And',
    selectedTags: [],
    selectedExcludeTags: [],
    languageTab: 'And',
    languageCondition: 'And',
    selectedLanguages: [],
    connectedAtFrom: undefined,
    connectedAtTo: undefined,
    lastMessagedFrom: undefined,
    lastMessagedTo: undefined,
    lastMessagedAtArray: undefined,
    birthDateFrom: undefined,
    birthDateTo: undefined,
    usersMutualCondition: 'And',
    selectedUsersMutual: [],
    noTags: false,
    sort: '',
    savedFilter: undefined
}

export const initialConnectionsFilter = {
    ...filters
}

const initialConnectionsParameters = {
    page: 1,
    pageSize: 50,
    total: 0,
    columnsSetup: {
        "lastMessagedColumn": true,
        "followerCountColumn": false,
        "message": true,
        "note": true,
        "profilePictureMapped": true,
        "fullName": true,
        "headlineAndJobTitle": true,
        "company": true,
        "location": true,
        "dateConnectedColumn": false,
        "emailColumn": true,
        "tagsColumn": true,
        "languagesColumns": false,
        "dateOfBirth": false,
        "mutualConnections": true
    }
} 

const initialConnectionsFilterOptions = {
    tagFilterOptions: [],
    languageFilterOptions: [],
    autotagsFilterOptions: []
}

const initialState: TConnectionsState = {
    connections: [],
    connectionsFilter: initialConnectionsFilter,
    connectionsParameters: initialConnectionsParameters,
    connectionFilterOptions: initialConnectionsFilterOptions
}

const reducer = (state: TConnectionsState, action: TConnectionAction): TConnectionsState => {
    switch (action.type) {
        case ActionType.SET_CONNECTIONS: return { ...state, connections: action.payload }
        case ActionType.SET_CONNECTIONS_FILTER: return { ...state, connectionsFilter: {...state.connectionsFilter, ...action.payload} }
        case ActionType.SET_CONNECTIONS_PARAMETERS: return { ...state, connectionsParameters: {...state.connectionsParameters, ...action.payload} }
        case ActionType.SET_CONNECTIONS_FILTER_OPTIONS: return { ...state, connectionFilterOptions: {...state.connectionFilterOptions, ...action.payload} }
        case ActionType.RESET_CONNECTIONS_PAGINATION: return {  ...state, connectionsParameters: {...initialConnectionsParameters }, connectionsFilter: {...initialConnectionsFilter} }
        case ActionType.RESET_STORE: return { ...initialState }
        default: return state
    }
}

const { StoreProvider: ConnectionsStoreProvider, useStoreContext: useConnectionsStore } = makeStore(reducer, initialState)

export { ConnectionsStoreProvider, useConnectionsStore }