import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import Table from '../../../../components/Table'
import Checkbox from '../../../../components/Checkbox'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import { AppRoutes, SidebarTypes } from '../../../../interfaces/store/appStore'
import { useTagsStore } from '../../../../stores/tags/useTagsStore'
import { ITag } from '../../../../interfaces/models/tag'
import moment from 'moment'
import ColorSelectDropdownFragment from '../../../../fragments/ColorSelectDropdownFragment'
import { styles } from '../../../../styles/themes/style'
import { useTagsHandlers } from '../../../../services/actions/tags/useTagsHandlers'
import EditDeleteActionDropdown from '../../../../fragments/dropdowns/EditDeleteActionDropdown'
import { calculateColumnWidth, capitalLetters } from '../../../../utils'
import { useAppStore } from '../../../../stores/application/useAppStore'
import ActionText from '../../../../components/ActionText'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import SvgFragment from '../../../../fragments/SvgFragment'
import Text from '../../../../components/Text'
import EmptyPage from '../../../../components/EmptyPage'
import Column from '../../../../components/Column'
import ProfilePhoto from '../../../../components/ProfilePhoto'
import useMediaQuery from '../../../../components/useMediaQuery'

interface ITagTableProps {
    selectedTags?: any
    setSelectedTags?: (e: any) => void
    isAllSelected?: boolean
    controlBarHeight?: number;
    setIsAllSelected?: (e: boolean) => void
}

const TagTable: FunctionComponent<ITagTableProps> = ({ selectedTags, setSelectedTags, isAllSelected, controlBarHeight, setIsAllSelected }) => {
    const tableRef = useRef<HTMLDivElement>(null)
    const isSmall = useMediaQuery("(max-width: 768px)")
    const { store: { tags, tagsParameters } } = useTagsStore()
    const navigate = useNavigate()
    const { openSidebar, setCustomColorModalHandler } = useAppHandlers()
    const { editTagHandler, deleteTagHandler } = useTagsHandlers()
    const { store: { requestLoading }} = useAppStore()
    const [ tagsLoading, setTagsLoading ] = useState<boolean>(true)

    const { pageSize } = tagsParameters

    const noResultTitle = "No Tags to Show";
    const noResultDescription = "Create and edit tags, keep them to yourself, or share them with your workspace members.";

    useEffect(() => {
        if('getTagsPaginate' in requestLoading) setTagsLoading(requestLoading?.['getTagsPaginate'])
    }, [requestLoading]);

    const sortHandler = (props: any) => {
        const selectedColumnIndex = columns.findIndex(column => column.key === props.key)
        if (selectedColumnIndex !== -1) {

            const updatedColumns = [...columns]
            const selectedColumn = updatedColumns[selectedColumnIndex]

            selectedColumn.sort = selectedColumn.sort === 'az' ? 'ez' : 'az'
            updatedColumns[selectedColumnIndex] = selectedColumn

            setColumns(updatedColumns)
        }
    }

    const selectAllCheckboxHandler = () => {
        setSelectedTags && setSelectedTags(!isAllSelected ? tags : [])
        setIsAllSelected && setIsAllSelected(!isAllSelected)
    }

    const selectCheckboxHandler = (selectedTag: any) => {
        const index = selectedTags.findIndex((tag: any) => tag._id === selectedTag._id)
        if (index !== -1) {
            const updatedTags = selectedTags.filter((tag: any) => tag._id !== selectedTag._id);
            setSelectedTags && setSelectedTags(updatedTags);
        } else {
            const updatedTags = [...selectedTags, selectedTag];
            setSelectedTags && setSelectedTags(updatedTags);
        }
    }

    const onEditColorHandler = useCallback(async (tag: ITag, newColor: string) => {
        await editTagHandler(tag._id, { title: tag.title, private: tag.private, color: newColor });
    }, [tags])

    const openCustomColorModalHandler = (tag: ITag) => setCustomColorModalHandler(tag?.color ?? styles.colors.primary600, (newColor) => onEditColorHandler(tag, newColor))

    const [columns, setColumns] = useState([
        { header: 'Color', key: ['colorSelect'], show: true, width: '75px', showSmall: true },
        { header: 'Tag name', key: ['title'], show: true, sort: '', sortHandler: (e: any) => sortHandler(e), showSmall: true },
        { header: 'Tagged items', key: ['connectionsCountColumn'], show: true, showSmall: true },
        { header: 'Date created', key: ['createdAtMoment'], show: true },
        { header: 'Shared tags', key: ['mutualTags'], show: true },
        { header: 'Access', key: ['access'], show: true, width: '75px' },
        { header: 'Actions', key: ['actions'], show: true, showSmall: true }
    ])

    useEffect(() => {
        const offsetWidth = tableRef.current?.offsetWidth
        const adjustedColumns = calculateColumnWidth(offsetWidth, columns, isSmall)
        setColumns(adjustedColumns)
    }, [tags, isSmall])

    return <Table
        ref={tableRef}
        pageLoading={!tags?.length && tagsLoading ? pageSize : undefined}
        noDataPage={<EmptyPage svgType='tagEmpty' title={noResultTitle} label={noResultDescription} />}
        $controlBarHeight={controlBarHeight}
        columns={[
            {
                header: <StyledCheckbox checked={isAllSelected} onClickHandler={() => selectAllCheckboxHandler()} checkboxId={'checkbox'} />,
                key: ['checkbox'],
                width: '36px',
                showSmall: true
            },
            ...columns
        ]}
        data={tags.map((tag: ITag) => {
            return (
                {
                    object: { ...tag },
                    ...!tag.isForbidden ? { onRowClick: () => openSidebar(SidebarTypes.TAG_EDIT_CREATE, { tag }) } : {},
                    checkbox: (
                        <StyledCheckbox
                            checked={selectedTags.some((selectedTag: any) => selectedTag?._id === tag?._id)}
                            checkboxId={tag._id}
                            onClickHandler={(e: any) => { e.stopPropagation(); selectCheckboxHandler(tag) }}
                        />
                    ),
                    mutualTags: (
                        <Column>
                            {tag?.users?.map((user: any) => { 
                                return <ProfilePhoto tooltipMessage={capitalLetters(user, true)} capitalLetters={capitalLetters(user)} />})}
                        </Column>
                    ),
                    access: tag.private ? 'Private' : 'Public',
                    createdAtMoment: (
                        moment(tag.createdAt).format('MMM D, YYYY [at] HH:mm')
                    ),
                    colorSelect: (
                        <div onClick={(e: any) => e.stopPropagation()}>
                            <ColorSelectDropdownFragment
                                disabled={tag.isForbidden}
                                customDesign={tag.isForbidden}
                                color={tag?.color ?? styles.colors.primary600}
                                onColorChangedHandler={(color: string) => onEditColorHandler(tag, color)}
                                openCustomColorModalHandler={() => openCustomColorModalHandler(tag)}
                            />
                        </div>
                    ),
                    connectionsCountColumn: (<>{
                        tag.connectionsCount > 0 ? 
                            <ActionText onClickHandler={(e: any) => {e.stopPropagation(); navigate(AppRoutes.CONNECTIONS,  { state: { tag } })}}>{tag.connectionsCount + ' Tagged'}</ActionText>
                                : <Text $faded $bold>{tag.connectionsCount + ' Tagged'}</Text>
                        }</>
                    ),
                    actions: !tag.isForbidden ? (
                        <div style={{display: 'flex', justifyContent: 'flex-end'}} onClick={(e: any) => e.stopPropagation()}>
                            <EditDeleteActionDropdown
                                object={tag}
                                type='tag'
                                onDeleteHandler={() => deleteTagHandler(tag._id)}
                                onEditHandler={() => openSidebar(SidebarTypes.TAG_EDIT_CREATE, { tag })}
                            />
                        </div>) : null,
                    ...tag
                }
            )
        })}
    />
}
const StyledCheckbox = styled(Checkbox)`
    height: fit-content;
`

export default TagTable
