import { FunctionComponent, ReactNode, useState } from "react"
import styled from "styled-components"
import { styles } from "../../styles/themes/style";
import { DropdownButton } from 'react-bootstrap'
import SvgFragment from "../../fragments/SvgFragment";
import ProfilePhoto from "../ProfilePhoto";
import TooltipWrapper from "../TooltipWrapper";

interface IStyledDropdownProps {
    $hideToggle?: boolean,
    selectStyle?: boolean,
    $unsetOverflow?: boolean,
    $transparent?: boolean,
    $backgroundColor?: string
}

interface IDropdownProps extends IStyledDropdownProps {
    title?: ReactNode,
    tooltipMessage?: string,
    SVGtype?: string,
    profileImage?: boolean,
    children?: ReactNode,
    imgSource?: string,
    autoClose?: string
    drop?: string
    disabled?: boolean,
    className?: string,
    show?: boolean,
    hideArrow?: boolean,
    backgroundColor?: string
}
/**
 * Use dropdown menu for the action menus, or profile photo dropdowns
 */
const DropdownMenu: FunctionComponent<IDropdownProps> = ({ show, children, title, tooltipMessage, SVGtype, imgSource, disabled, $hideToggle, profileImage = false, hideArrow = false, backgroundColor, ...props }) => {

    const [ dropdownOpen, setDropdownOpen ] = useState<boolean>(false)
    //please don't use classes, this is just a quick fix so we have the tooltips
    const dropdownButtonTitleElement = <TooltipWrapper tooltipText={tooltipMessage ?? ''}>
        <div className="dropdownWrapperReference">
        {SVGtype && <SvgFragment type={SVGtype} />}
        {title}
        {profileImage && <ProfilePhoto source={imgSource} />}
        {!$hideToggle && !hideArrow && <StyledSvg dropdownOpen={dropdownOpen} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 7">
                <path fillRule="evenodd" d="M6.193 14.215l.623.6c.257.246.674.247.932 0L12 10.747l4.252 4.068c.258.247.675.247.932 0l.623-.6c.257-.247.257-.648 0-.896l-5.34-5.133c-.258-.248-.676-.248-.933 0l-5.34 5.133c-.258.247-.258.649 0 .896z" transform="translate(-209 -182) translate(30 120) translate(11 54) translate(162)"/>
            </StyledSvg>}
    </div></TooltipWrapper>;

    return (
        <StyledDropownButton $disabled={disabled} disabled={disabled} show={show} bsPrefix="actions-dropdown" title={dropdownButtonTitleElement} $hideToggle={$hideToggle} $backgroundColor={backgroundColor} onToggle={(isOpen: boolean) => setDropdownOpen(isOpen)} {...props}>
            {children}
        </StyledDropownButton>
    )
}

const StyledDropownButton = styled(DropdownButton) <IStyledDropdownProps>` 
    background-color: ${({ theme: { secondaryColor }, $backgroundColor }) => $backgroundColor ?? secondaryColor};
    ${({$transparent}) => $transparent && `
        background-color: transparent;`}
    border-radius: 3px;
    transition: all .2s ease;
    .dropdown-toggle, .dropdownWrapperReference {
        display: flex;
        align-items: center;
        gap: 5px;  
        font-family: NunitoSans;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        border-radius: 5px;
        border: none;
        width: 100%;
        background: transparent;
        color:  ${styles.colors.primary600};
        display: flex;
        align-items: center;
        &:hover { 
            .LDtooltip { display: block; }
            color: ${styles.colors.primary500};
            border: none;
            svg path { fill: ${styles.colors.primary600} }
        }
    }
    .actions-dropdown + div{
        padding: 5px 0px;
        max-height: 500px;
        overflow-y: auto;
        ${({$unsetOverflow}) => $unsetOverflow && `overflow: unset;`}
        min-width: 170px;
        z-index: 1005;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
        border: none;
        background-color: ${({ theme: { secondaryColor } }) => secondaryColor};
    }
    ${( { selectStyle, theme: { quinaryColor, quaternaryColor } } ) => selectStyle && `
        button, .dropdown-item {
            font-weight: normal;
        }
        .dropdown-toggle {
            width: 100%;
            outline: 2px solid ${quinaryColor};
            padding: 0px 6px;
            &:hover, &:focus { outline: 2px solid ${(styles.colors.primary600)}; }
        }`}
    .dropdown-toggle::after {
        display: none !important; 
    }
    ${({$disabled, theme: { senaryColor, quinaryColor } }) => $disabled && `
        background-color: ${senaryColor};
        border: 1px solid transparent;
        cursor: default;

        & svg { path { fill: ${quinaryColor} !important; } }
    `} 
`

const StyledSvg = styled.svg<{ dropdownOpen: boolean }>`
    fill: ${({dropdownOpen, theme: { quaternaryColor }}) => dropdownOpen ? quaternaryColor: styles.colors.black300 };
    transition: all .2s ease;
    cursor: pointer;
    transform: rotate(180deg);
    ${({dropdownOpen}) => dropdownOpen && 'transform: rotate(360deg);'}
    &:hover{ fill: ${props => props.theme.primaryColor }};
`

export default DropdownMenu