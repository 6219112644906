import { FunctionComponent } from "react";
import Tag from "../components/Tag";
import Column from "../components/Column";
import { styles } from "../styles/themes/style";
import DropdownMenu from "../components/dropdown/DropdownMenu";
import DropdownItem from "../components/dropdown/DropdownItem";
import AppTag from "./AppTag";

interface IConnectionTagsProps {
    tags: any
    tagColumn?: any
    backgroundColor?: string;
}

const ConnectionTags: FunctionComponent<IConnectionTagsProps> = ({ tags, tagColumn, backgroundColor }) => {
    const dropdownButton = 
       <Column gap="5px">{
            tags?.sort((a: any, b: any) => {
                if(!a.isForbidden) return -1
                if(!b.isForbidden) return 1
                return 0
            })?.slice(0, 2).map((tag: any) => {
                return <AppTag key={tag?._id} $width={tagColumn?.width ?? undefined} tag={tag} />
            })}
         {tags?.length > 2 && <Tag $backgroundColor={styles.colors.white} $borderColor={styles.colors.primary600} $color={styles.colors.white} title={`${tags.length - 2} more`} SVGtype={'plus'} /> }
        </Column>
    return (
        <div onClick={(e: any) => e.stopPropagation()} >
            <DropdownMenu title={dropdownButton} $hideToggle $backgroundColor={backgroundColor}>
            {tags?.sort((a: any, b: any) => {
                if(!a.isForbidden) return -1
                if(!b.isForbidden) return 1
                return 0
            })?.map((tag: any) => {
                return <DropdownItem key={tag?._id} $nonHoverable><AppTag tag={tag} /></DropdownItem>
            })}
            </DropdownMenu>
        </div>
    )
}


export default ConnectionTags